<template>
  <div class="faq">
    <Preloader :loading="loading" />
    <div v-for="ticket in tickets" v-bind:key="ticket.id" class="d-flex">
      <Code :ticket="ticket" :redirect="redirect" />
    </div>
  </div>
</template>

<script>
import Preloader from "@/components/common/Preloader";
import Code from "@/components/ticketsRegistration/CodeCard";

export default {
  name: "SpeakerCodes",
  props: {
    tickets: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: { Code, Preloader },
  data: () => ({}),
  methods: {
    redirect(ticket, action) {
      localStorage.removeItem("chosen-code");

      localStorage.setItem(
        "codes-token",
        JSON.stringify(this.$route.params.token)
      );
      localStorage.setItem(
        "chosen-speaker-code",
        JSON.stringify({
          id: ticket.id,
          level: ticket.level,
          title: ticket.title,
          claimed: ticket.claimed,
          limit: ticket.limit,
          description: ticket.description,
        })
      );
      if (action === "invite") {
        this.$router.replace(`/${this.$route.params.id}/invite/${ticket.id}`);
      } else {
        this.$router.replace(
          `/${this.$route.params.id}/register-multiple/${ticket.id}`
        );
      }
    },
  },
};
</script>
