<template>
  <SpeakerKit />
</template>

<script>
import SpeakerKit from "@/components/speakerKit/SpeakerKit";

export default {
  name: "SpeakerKitPage",
  props: {},
  components: {
    SpeakerKit,
  },
  data: () => ({}),
};
</script>
