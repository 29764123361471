<template>
  <div class="kit">
    <Preloader
      v-if="isKitLoading"
      :loading="isKitLoading"
      class="kit__loader"
    />
    <div class="kit__tabs">
      <div
        @click.prevent="setActiveTab('bio')"
        :class="{ active: isTabActive('bio') }"
      >
        Bio
      </div>

      <div
        @click.prevent="setActiveTab('talk')"
        :class="{ active: isTabActive('talk') }"
      >
        Talk
      </div>
      <div
        @click.prevent="setActiveTab('codes')"
        :class="{ active: isTabActive('codes') }"
      >
        Codes
      </div>
      <div
        @click.prevent="setActiveTab('lounge')"
        :class="{ active: isTabActive('lounge') }"
      >
        General information
      </div>
      <div
        @click.prevent="setActiveTab('accommodation')"
        :class="{ active: isTabActive('accommodation') }"
      >
        Accommodation
      </div>
      <div
        @click.prevent="setActiveTab('profile')"
        :class="{ active: isTabActive('profile') }"
      >
        Update Profile
      </div>
    </div>
    <div v-if="kit" class="kit__content padding-x">
      <div v-show="isTabActive('bio')">
        <Bio :bio="kit.bio" :keyNote="kit.is_keynote" />
      </div>
      <div v-show="isTabActive('codes')" class="kit__codes">
        <Error v-if="codesError">
          <template #heading>
            Sorry, something went wrong while loading codes
          </template>
          <template #body>
            Please try again or contact us at
            <a href="mailto:info@arctic-con.com" class="error__link">
              info@arctic-con.com
            </a>
          </template>
        </Error>
        <div v-else>
          <Heading noDecoration class="kit__codes-title"> Codes</Heading>
          <div class="kit__codes-block">
            <SpeakerCodes
              :tickets="codes"
              :loading="isOrderLoading"
              class="kit__codes-block-first"
            />
            <SpeakerCodesFAQ />
          </div>
        </div>
      </div>
      <div v-show="isTabActive('talk')">
        <TalkInfo :talk="kit.talk" :track="kit.track" />
      </div>
      <div v-show="isTabActive('lounge')">
        <Lounge
          :instructions="info.speaker_instructions"
          :loading="isInfoLoading"
        />
      </div>
      <div v-show="isTabActive('accommodation')">
        <AccommodationInfo
          :accommodations="info.accommodation"
          :loading="isInfoLoading"
        />
      </div>
      <div v-show="isTabActive('profile')">
        <UpdateProfile
          :sponsor="kit"
          :sponsorLoading="isKitLoading"
          :is-sponsor="false"
        />
      </div>
    </div>
    <Error v-if="kitError" class="error">
      <template #heading> Something went wrong</template>
      <template #body>
        Please try again or contact us at
        <a href="mailto:info@arctic-con.com" class="error__link">
          info@arctic-con.com
        </a>
      </template>
    </Error>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Preloader from "@/components/common/Preloader";
import Heading from "@/components/common/Heading";
import Error from "@/components/common/Error";
import Bio from "./Bio";
import TalkInfo from "./TalkInfo";
import Lounge from "./Lounge";
import SpeakerCodesFAQ from "./SpeakerCodesFAQ";
import SpeakerCodes from "./SpeakerCodes";
import AccommodationInfo from "../exhibitorKit/AccommodationInfo";
import UpdateProfile from "../exhibitorKit/UpdateProfile";

export default {
  name: "ExhibitorKit",
  props: {},
  components: {
    Preloader,
    UpdateProfile,
    Heading,
    SpeakerCodes,
    SpeakerCodesFAQ,
    Bio,
    AccommodationInfo,
    Error,
    TalkInfo,
    Lounge,
  },
  data: () => ({
    activeTab: "bio",
    rechecking: true,
    contactForm: false,
    isKitLoading: false,
    isOrderLoading: false,
    isInfoLoading: false,
    info: null,
    infoError: null,
    kit: null,
    kitError: null,
    codes: null,
    codesError: null,
  }),
  computed: {
    ...mapState(["currentEventID"]),
    adminUrl() {
      return process.env.VUE_APP_ADMIN_PANEL_URL;
    },
    eventId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    if (history.state.forward) {
      this.activeTab = "bio"; //for redirect with browser "back" arrow
    } else if (
      history.state.back &&
      (history.state.back.includes("invite") ||
        history.state.back.includes("register-multiple"))
    ) {
      this.activeTab = "codes";
    } else {
      this.activeTab = "bio";
    }

    this.isKitLoading = true;
    this.isOrderLoading = true;
    this.isInfoLoading = true;
    this.getKitInfo();
    this.getOrder();
    this.getInstructionsInfo();
  },
  methods: {
    ...mapActions(["GET"]),
    getKitInfo() {
      return this.GET({
        route: `/public/event/${
          this.eventId || this.currentEventID
        }/speaker_kit/${this.$route.params.token}`,
      })
        .then((resp) => {
          this.kit = resp.data;
          this.isKitLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.kitError = true;
          this.isKitLoading = false;
        });
    },
    getOrder() {
      return this.GET({
        route: `/ticketing/public/event/${
          this.eventId || this.currentEventID
        }/order/${this.$route.params.token}`,
      })
        .then((resp) => {
          this.codes = resp.data;
          this.isOrderLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.codesError = true;
          this.isOrderLoading = false;
        });
    },
    getInstructionsInfo() {
      return this.GET({
        route: `/public/event/${this.$route.params.id}/exhibitor_kit`,
      })
        .then((resp) => {
          this.info = resp.data;
          this.isInfoLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.infoError = true;
          this.isInfoLoading = false;
        });
    },
    isTabActive(menuItem) {
      return this.activeTab === menuItem;
    },
    setActiveTab(menuItem) {
      this.activeTab = menuItem;
    },
    openHelp() {
      this.rechecking = false;
      this.contactForm = true;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";
.kit {
  padding-top: 70px;

  &__loader {
    height: 50vh;
    align-items: center;
  }

  &__tabs {
    position: fixed;
    top: 72px;
    width: 100%;
    z-index: 5;
    background-color: $white;
    border-top: 1px solid $dark-gray;
    border-bottom: 1px solid $dark-gray;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-x: hidden;

    div {
      cursor: pointer;
      padding: 10px;
      font-size: 14px;
      color: $dark-gray;
      text-transform: uppercase;
    }
  }

  &__tabs .active {
    background-color: $light-gray;
  }

  &__content {
    padding-top: 135px;
    padding-bottom: 35px;
  }

  &__codes {
    &-title {
      margin-bottom: 28px;
    }
  }

  .banner {
    max-width: 672px;
    padding: 18px 23px;
    background-color: $light-gray;
    margin-bottom: 17px;

    &-title {
      font-family: $sarabun;
      font-size: 32px;
      font-weight: $sarabunBold;
      color: $dark-gray;
      letter-spacing: 0.08em;
    }

    &-btns {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 28px;

      button {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  &__panel {
    padding-top: 100px;
    padding-bottom: 120px;
    background-color: $light-gray;
  }

  @media (min-width: $media-xs) {
    &__content {
      padding-top: 85px;
    }
  }

  @media (min-width: $media-sm) {
    padding-top: 122px;
    &__tabs {
      top: 109px;
    }
    &__codes {
      &-title {
        margin-bottom: 56px;
      }

      &-block {
        display: flex;
        flex-wrap: wrap;

        &-first {
          width: 100%;
        }
      }
    }

    .banner {
      min-width: 374px;
      height: fit-content;

      &-btns {
        button {
          width: 47%;
        }
      }
    }
  }

  @media (min-width: $media-md) {
    &__tabs {
      div {
        font-size: 16px;
        padding: 24px 50px;
      }
    }

    &__codes {
      &-block {
        flex-wrap: nowrap;

        &-first {
          width: 50%;
          padding-right: 24px;
        }
      }
    }
  }

  @media (min-width: $media-lg) {
    &__content {
      padding-top: 129px;
      padding-bottom: 136px;
    }
    &__codes {
      &-title {
        margin-bottom: 87px;
      }
    }
    .banner {
      max-width: 760px;
      padding: 53px 72px;

      button {
        width: 40%;
      }
    }
  }
}

.error {
  padding: 70px 0;
  text-align: center;
  @media (min-width: $media-sm) {
    padding: 122px 0;
  }
}
</style>
