<template>
  <Heading noDecoration text-align="center">General information</Heading>
  <div
    class="lounge"
    v-for="[topic, list] in Object.entries(instructions)"
    :key="topic"
  >
    <Heading noDecoration type="h4" class="subheader">
      {{ topic }}
    </Heading>
    <ul class="tech" style="max-width: 951px">
      <li
        v-for="(item, index) in list"
        :key="item.slice(0, 5) + index"
        class="timing__list-item"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";

export default {
  name: "Lounge",
  props: {
    instructions: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { Heading },
  data: () => ({
    copied: false,
  }),
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.lounge {
  max-width: 951px;
  text-align: justify;

  &__video {
    &-title {
      color: $dark-gray;
      font-weight: bold;
    }
  }

  .tech {
    padding: 0;
  }

  ul {
    margin: 0;
  }

  ul.tech {
    list-style-type: none;
  }

  ul.tech > li {
    text-indent: -5px;
  }

  ul.tech > li:before {
    content: "-";
    text-indent: -5px;
    padding-right: 5px;
  }
}
</style>
