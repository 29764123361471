<template>
  <Heading noDecoration text-align="center">Talk information</Heading>
  <div class="accommodation">
    <div>
      <Heading noDecoration type="h4">Talk Title</Heading>
      <p class="mb-4">{{ talk.title }}</p>
      <Heading noDecoration type="h4">Track</Heading>
      <p class="mb-4">{{ makeFieldName(track) }}</p>
      <Heading noDecoration type="h4">Talk Description</Heading>
      <p class="mb-4">{{ talk.description }}</p>
    </div>
  </div>
</template>

<script>
import { makeFieldName } from "@/assets/js/utils";
import Heading from "@/components/common/Heading";

export default {
  name: "TalkInfo",
  props: {
    talk: {
      type: Object,
      default: () => ({}),
    },
    track: {
      type: String,
      default: "",
    },
  },
  components: { Heading },
  data: () => ({}),
  computed: {},
  methods: {
    makeFieldName,
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.accommodation {
  margin-top: 15px;
  @media (min-width: $media-sm) {
    margin-top: 33px;
  }

  @media (min-width: $media-lg) {
    margin-top: 64px;
  }
}
</style>
